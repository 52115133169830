import "./css/styles.css";

let cookieName;
let trackingIDs; // ID string or array of ID strings. Multiple GA4 or AW IDs can be used in combination. But if GTM is used it must be sole.
let cookieDuration;
let texts = {};
let gtagIndexes = []; // array of indexes to append to "gtag" functions. For each GA4/AW ID we need a uniquely named gtag function.

/* === Callable functions === */
function cookieStart(g_id, texts_arg) {
    trackingIDs = g_id;
    texts = texts_arg;
    cookieDuration = 365;
    cookieName = "cookieconsentpreference";
    cookieConsentInit();
}

function initCookielessUA(ua_id, user_ip) {
    return;
}
/* === End of Callable functions === */

function cookieConsentInit() {
    const cookiePreference = getCookie(cookieName);

    if (cookiePreference === null) {
        showCookiePopup();
    }

    setAnalytics();

    let waitCount = 0;
    // listener for modal open button by id
    let waitForBtn = setInterval(() => {
        waitCount++;
        let openManageBtn = document.getElementById("cc-global-manage");
        if (openManageBtn) {
            openManageBtn.addEventListener("click", showManageModal);
            clearInterval(waitForBtn);
        }
        if (waitCount > 5) clearInterval(waitForBtn);
    }, 150);

    // listener for modal open buttons by class
    waitCount = 0;
    let waitForBtns = setInterval(() => {
        waitCount++;
        let openManageBtns = document.querySelectorAll(".a101cc-open-manage-modal");
        if (openManageBtns) {
            for (const btn of openManageBtns) {
                btn.addEventListener("click", showManageModal);
                clearInterval(waitForBtns);
            }
        }
        if (waitCount > 5) clearInterval(waitForBtns);
    }, 150);
}

function acceptAllCookies() {
    hideCookiePopup();

    // find which cookies we use and set them all
    let cookieVal = "";
    if (texts.modal.analytical) {
        cookieVal += ",analytical";
    }
    if (texts.modal.advertising) {
        cookieVal += ",advertising";
    }

    setCookie(cookieName, cookieVal, cookieDuration);
    updateAnalytics();
}

// only called on page load
function setAnalytics() {
    /* handle Google analytics */

    // if we have an array of multiple GA4 or AW IDs
    if (typeof trackingIDs === "object" && trackingIDs.length > 1) {
        let gtagCounter = 1;

        trackingIDs.forEach((id) => {
            setGoogleAnalytics(id, gtagCounter);
            gtagCounter++;
        });
    } else {
        setGoogleAnalytics(trackingIDs, 1);
    }

    /* === handle Meta pixel === */
    setMetaAnalytics();
}

function setGoogleAnalytics(gID, gtagIndex) {
    gtagIndexes.push(gtagIndex);

    /*
    Detect GA4 or GTM or AW based on ID format
        GA4 ID format: G-XXXXXXX
        GTM ID format: GTM-XXXXXXX
        AW ID format: AW-XXXXXXXXX
    */
    // GA4 or AW
    if (gID.split("-")[0] === "G" || gID.split("-")[0] === "AW") {
        addScriptToHead(null, null, `https://www.googletagmanager.com/gtag/js?id=${gID}`, true);
        addScriptToHead(
            null,
            `window.dataLayer${gtagIndex} = window.dataLayer${gtagIndex} || []; function gtag${gtagIndex}(){dataLayer${gtagIndex}.push(arguments);} gtag${gtagIndex}('js', new Date()); gtag${gtagIndex}('config', '${gID}');`,
            null
        );
        // by default deny all cookie storages
        addScriptToHead(
            null,
            `gtag${gtagIndex}('consent', 'default', { 'ad_storage': 'denied', 'analytics_storage': 'denied' });`,
            null
        );
    } else if (gID.split("-")[0] === "GTM") {
        // GTM
        addScriptToHead(
            null,
            `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','${gID}');`,
            null
        );
    }

    // if any consents are already granted, update them
    if (checkConsent("analytical")) {
        addScriptToHead(null, `gtag${gtagIndex}('consent', 'update', { 'analytics_storage': 'granted' });`, null);
    }
    if (checkConsent("advertising")) {
        addScriptToHead(null, `gtag${gtagIndex}('consent', 'update', { 'ad_storage': 'granted' });`, null);
    }
}

function setMetaAnalytics() {
    if (texts.pixel?.id && texts.modal.advertising) {
        addScriptToHead(
            null,
            `!function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');`,
            null
        );

        if (checkConsent("analytical") && checkConsent("advertising")) {
            // we allow fbq if full consent
            addScriptToHead(null, `fbq('consent', 'grant');`, null);
        } else {
            addScriptToHead(null, `fbq('consent', 'revoke');`, null);
        }

        addScriptToHead(null, `fbq('init', '${texts.pixel.id}');fbq('track', 'PageView');`, null);
    }
}

// called when updating cookie preferences
function updateAnalytics() {
    /*  === for Google analytics === */
    let analytics_consent = checkConsent("analytical") ? "granted" : "denied";
    let ad_consent = checkConsent("advertising") ? "granted" : "denied";

    // apply update to all dataLayers
    gtagIndexes.forEach((gtagIndex) => {
        addScriptToHead(
            null,
            `gtag${gtagIndex}('consent', 'update', { 'analytics_storage': '${analytics_consent}' });`,
            null
        );
        addScriptToHead(null, `gtag${gtagIndex}('consent', 'update', { 'ad_storage': '${ad_consent}' });`, null);
    });

    /* === for Meta Pixel === */
    if (texts.pixel?.id && texts.modal.advertising) {
        // at this point pageView script already exists on page. It will get sent automatically if consent gets granted, even if later
        if (checkConsent("analytical") && checkConsent("advertising")) {
            addScriptToHead(null, `fbq('consent', 'grant');`, null);
        } else {
            addScriptToHead(null, `fbq('consent', 'revoke');`, null);
        }
    }
}

// note: consented cookies are stored in a string delimmited by comma (,)
function savePreferences() {
    let newCookieVal = "";
    let countChecked = 0;
    let checkboxes = document.getElementsByClassName("cookie-type-preference-checkbox");

    for (const a of checkboxes) {
        if (a.checked) {
            countChecked++;
            newCookieVal += "," + a.value;
        }
    }

    if (countChecked === 0) {
        newCookieVal = "none";
        deleteAllCookies();
    }

    setCookie(cookieName, newCookieVal, cookieDuration);
    hideManageModal();
    updateAnalytics();
}

function showCookiePopup() {
    // if popup already exists, remove. Can happen on websites that need to re-init a101cc (looking at you agencija101.si)
    let oldPopup = document.getElementById("a101cc-cookie-popup");
    if (oldPopup) oldPopup.remove();

    let popup = document.createElement("div");
    popup.innerHTML = `<div class="cookie-popup a101cc-cookie-popup" id="a101cc-cookie-popup"><p>${texts.bannerText}</p><div><button class="a101cc-banner-btn a101cc-accept-all-btn" id="cc-accept-all">${texts.acceptAllBtnText}</button><button class="a101cc-banner-btn a101cc-manage-btn" id="cc-show-manage-modal">${texts.managePopupBtnText}</button></div></div>`;
    document.body.appendChild(popup);

    let acceptAllBtn = document.getElementById("cc-accept-all");
    let manageBtn = document.getElementById("cc-show-manage-modal");
    acceptAllBtn.addEventListener("click", acceptAllCookies);
    manageBtn.addEventListener("click", showManageModal);
}

function showManageModal() {
    hideCookiePopup();

    // if for any reason modal exists, remove.
    let oldModal = document.getElementById("a101cc-manage-modal");
    if (oldModal) oldModal.remove();

    let modal = document.createElement("div");
    let modalContent = `<div class="nice-modal a101cc-modal a101cc-manage-modal" id="a101cc-manage-modal">
                            <div class="modal-inner">
                                ${texts.modal.firstText ? "<div><p>" + texts.modal.firstText + "</p></div>" : ""}
                                <div>
                                    <h3>${texts.modal.mainTitle}</h3>
                                    <p>${texts.modal.subTitleText ? texts.modal.subTitleText : ""}</p>
                                </div>
                                <div>
                                    <h4>${texts.modal.necessary}</h4>
                                    <p>${texts.modal.necessaryDescription ? texts.modal.necessaryDescription : ""}</p>
                                </div>`;

    if (texts.modal.analytical) {
        modalContent += `<div>
                            <h4>${texts.modal.analytical}</h4>
                            <p>${texts.modal.analyticalDescription ? texts.modal.analyticalDescription : ""}</p>
                            <form>
                                <input id="analiticni" class="cookie-type-preference-checkbox" type="checkbox" name="analiticni" value="analytical" ${
                                    checkConsent("analytical") ? "checked" : ""
                                } />
                                <label for="analiticni">${texts.modal.checkboxTxt}</label>
                            </form>
                            <br />
                        </div>`;
    }

    if (texts.modal.advertising) {
        modalContent += `<div>
                            <h4>${texts.modal.advertising}</h4>
                            <p>${texts.modal.advertisingDescription ? texts.modal.advertisingDescription : ""}</p>
                            <form>
                                <input id="oglasevalski" class="cookie-type-preference-checkbox" type="checkbox" name="oglasevalski" value="advertising" ${
                                    checkConsent("advertising") ? "checked" : ""
                                } />
                                <label for="oglasevalski">${texts.modal.checkboxTxt}</label>
                            </form>
                            <br />
                        </div>`;
    }

    modalContent += `   <div>
                                <br>
                                <button class="a101cc-save-prefs-btn" id="a101cc-save-prefs">${texts.modal.saveBtnText}</button>
                            </div>
                        </div>
                    </div>`;

    modal.innerHTML = modalContent;
    document.body.appendChild(modal);
    document.getElementById("a101cc-save-prefs").addEventListener("click", savePreferences);
    document.body.classList.add("a101cc-noscroll");

    // listeners to close manage modal

    // click on backdrop but not on elements within .modal-inner
    document.getElementById("a101cc-manage-modal").addEventListener("click", function (event) {
        let target = event.target;
        while (target && target !== this) {
            if (target.matches(".modal-inner")) {
                return;
            }
            target = target.parentNode;
        }
        const cookiePreference = getCookie(cookieName);
        if (cookiePreference != null) {
            hideManageModal();
        }
    });

    // escape key press listener
    document.addEventListener("keydown", escapeListener);
}
function escapeListener(event) {
    if (event.key === "Escape") {
        const cookiePreference = getCookie(cookieName);
        if (cookiePreference != null) {
            hideManageModal();
        }
    }
}

function hideCookiePopup() {
    let popup = document.getElementById("a101cc-cookie-popup");
    if (popup) popup.remove();
}

function hideManageModal() {
    let modal = document.getElementById("a101cc-manage-modal");
    if (modal) {
        document.body.classList.remove("a101cc-noscroll");
        modal.remove();
        document.removeEventListener("keydown", escapeListener);
    }
}

/* === Helper functions === */
function addScriptToHead(scriptId = null, scriptTextContent = null, scriptSrc = null, async = false) {
    const head = document.querySelector("head");
    const script = document.createElement("script");
    script.type = "text/javascript";
    if (scriptId) script.id = scriptId;
    if (async) script.async = true;
    if (scriptTextContent) script.textContent = scriptTextContent;
    else if (scriptSrc) script.src = scriptSrc;
    head.appendChild(script);
}

// Check if specified cookie type is consented.
// cookieType must be 'analytical' or 'advertising'
function checkConsent(cookieType) {
    const cookieVal = getCookie(cookieName);
    if (!cookieVal || cookieVal === "none") {
        return false;
    }

    const consentedCookies = cookieVal.split(",");
    return consentedCookies.includes(cookieType);
}

function setCookie(name, value, days) {
    let expires;
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toGMTString();
    } else expires = "";
    document.cookie = name + "=" + value + expires + "; path=/";
}

function getCookie(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function deleteAllCookies() {
    // todo: deleting all cookies ok?
    let cookies = document.cookie.split("; ");
    for (let c = 0; c < cookies.length; c++) {
        let d = window.location.hostname.split(".");
        while (d.length > 0) {
            let cookieBase =
                encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
                "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
                d.join(".") +
                " ;path=";
            let p = location.pathname.split("/");
            document.cookie = cookieBase + "/";
            while (p.length > 0) {
                document.cookie = cookieBase + p.join("/");
                p.pop();
            }
            d.shift();
        }
    }
}
/* === End of Helper functions === */

export { cookieStart, initCookielessUA };
